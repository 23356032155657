var myLazyLoad = '';
jQuery(document).ready(function($) {
    myLazyLoad = new LazyLoad();
    if(myLazyLoad) {
        myLazyLoad.update();
    }

    // Inits here
    init_header();
    init_banner();
});
