function init_banner() {
    var $swiper_wrapper = $('.cpx-swiper-banner .swiper-wrapper');

    if($swiper_wrapper && $swiper_wrapper.children('.swiper-slide').length > 1) {
      var swiper = new Swiper('.swiper-container.cpx-swiper-banner', {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        slidesPerView: 1,
        autoHeight: true,
        grabCursor: true,
        effect: 'slide',

        autoplay: {
          delay: 5000,
        },

        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      $('.cpx-swiper-banner').addClass('cpx-swiper-multiple-slides');
    }
    else {
      $('.cpx-swiper-banner').addClass('cpx-swiper-single-slide');
    }
}

$(document).ready(function() {
    init_banner();
});