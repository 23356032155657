function init_galerij_items() {
    var $items = $('[data-toggle="galerij_modal"]');

    // If items are present
    if($items.length > 0) {
        $items.on('click', function() {
            render_galerij_item($(this));
        });
    }
}

function render_galerij_item($item) {
    var $single_item = $item;

    var item_props = {
        'id': '',
        'title': '',
        'image': ''
    };

    if($single_item.attr('data-galerij-id')) {
        item_props.id = $single_item.attr('data-galerij-id');
    }
    if($single_item.attr('data-galerij-title')) {
        item_props.title = $single_item.attr('data-galerij-title');
    }
    if($single_item.attr('data-galerij-image')) {
        item_props.image = $single_item.attr('data-galerij-image');
    }

    // If no id or image, skip this iteration
    if(!item_props.id || !item_props.image) {
        console.error('Galerij item cannot be rendered, id or image missing!');
        return;
    }

    // Reset existing galerij modals
    var modal_exists = false;
    var $existing_modal = $('#'+item_props.id);
    if($existing_modal.length) {
        modal_exists = true;
    }

    // Checks finished: render the modal
    $footer = $('footer.footer');

    if($footer.length < 1) {
        console.error('Galerij item cannot be rendered, footer mount missing!');
        return;
    }

    // Setup modal HTML
      var html = `<div class="modal fade galerij-modal" id="`+item_props.id+`" tabindex="-1" role="dialog" aria-labelledby="Galerij" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="Galerij">`+item_props.title+`</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <img src="`+item_props.image+`" alt="`+item_props.title+`">
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">Sluiten</button>
          </div>
          </div>
      </div>
    </div>`;

    if(!modal_exists) {
        $footer.after(html);
    }

    // Get the element
    var $modal = $('#'+item_props.id);

    // Bootstrap show modal
    $modal.modal({
        keyboard: true,
        focus: true,
        show: true
    });
}

$(document).ready(function() {
    init_galerij_items();
});