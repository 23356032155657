function init_laatste_projecten() {
    var $swiper_wrapper = $('.cpx-laatste-projecten .swiper-wrapper');

    if($swiper_wrapper && $swiper_wrapper.children('.swiper-slide').length > 1) {
      var swiper = new Swiper('.swiper-container.cpx-laatste-projecten', {
        // Optional parameters
        direction: 'horizontal',
        // watchOverflow: true,
        grabCursor: true,

        slidesPerView: 'auto',
        spaceBetween: 30,
        freeMode: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
            dragSize: 100,
            snapOnRelease: false
        },
        on: {
          sliderFirstMove: function () {
            var $swipe_indication = $('.cpx-laatste-projecten').find('.slider-swipe-indication');

            if($swipe_indication && !$swipe_indication.hasClass('swipe-indication-triggered')) {
              $swipe_indication.addClass('swipe-indication-triggered');
            }
          },
        },
      });

      // $('.cpx-laatste-projecten').on("click",function(){
      //   var $this = $(this);
      //   var $swipe_indication = $this.find('.slider-swipe-indication');

      //   if($swipe_indication && !$swipe_indication.hasClass('swipe-indication-triggered')) {
      //     $swipe_indication.addClass('swipe-indication-triggered');
      //   }
      // });


    }
}

$(document).ready(function() {
    init_laatste_projecten();
});